import React from 'react';
import '../components/Footer.css';
function Footer(){
    return(
        <footer id="Footer">
            <p>Made with React JS :)  Copyright © 2017-2025 Scott Harrison</p>
        </footer>
    )
}
export default Footer;
